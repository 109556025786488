<template>
    <div class="d-flex justify-center waitloading">
        <div class="p-relative parentlogo">
            <img class="logo" src="@/assets/logo/short.svg" alt="">
            <img class="rotate logo" src="@/assets/logo/loading.svg" alt="">
        </div>
    </div>

</template>

<script>
export default {
}
</script>
<style lang="scss">
.waitloading{
    .parentlogo{
        height: 177px;
        width: 177px;
    }
    .logo{
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
    }
    .rotate{
        -webkit-animation: rotating 10s linear infinite;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
    }
    @-webkit-keyframes rotating {
        from{
            -webkit-transform: rotate(0deg);
        }
        to{
            -webkit-transform: rotate(360deg);
        }
    }
}
</style>
