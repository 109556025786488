<template>
    <v-btn
        class="custombutton"
        v-bind="$attrs"
        v-on="$listeners"
        :ripple="false"
        depressed
    >
        <slot />
    </v-btn>
</template>

<script>
export default {
    name: 'CustomButton',
}
</script>

<style lang="scss">
.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover::before{
    opacity: 1;
}
.custombutton.circle::before{
    content: " ";
    width: 60px !important;
    height: 60px !important;
    border-radius: 60px !important;
    transition: 0.2s !important;
    margin-top: -11px !important;
}
.custombutton.btnblue::before{
    content: " ";
    background-color: #1E5CFB;
    opacity: 0.2;
}
.custombutton.fullblue::before{
    content: " ";
    background-color: #1E5CFB;
    opacity: 1;
}
.btnfullblue{
    background: #1E5CFB!important;
    transition: 0.3s;
}
.btnfullblue::before{
    content: none;
}
@media all and (min-width: 930px){
    .custombutton.circle:hover::before{
        width: 100% !important;
        border-radius: 60px !important;
    }
    .custombutton.btnblue:hover{
        color: #ffffff;
    }
    .custombutton.btnblue:hover::before{
        opacity: 1;
    }
    .custombutton.btnblue:hover::before{
        opacity: 1;
    }
    .btnfullblue:hover{
        background: #0038C7!important;
    }
}
</style>
