<template>
    <div class="">
        <wait-loading v-if="loading != false"/>
        <div class="work mt-sm-16 pt-sm-10 mt-lg-0 pt-lg-0" v-if="item && !loading">
            <v-img :src="getBackground()" position="center center" class="p-absolute bg-image w-100" :height="bgheight"/>
            <!-- <div class="bgimage" :style="`background: url(` + getBackground() + `);`"></div> -->
            <div class="zi-1 p-relative welcomeblock" ref="welcomebox">
                <v-container class="mb-8 mb-md-16 pb-10 mt-lg-16 pt-lg-8">
                    <v-row>
                        <v-col cols="12" sm="8" md="6">
                            <router-link class="h6 fontw-700 montserrat blue1 d-flex align-center justify-center justify-sm-start mx-auto mx-md-0"  :class="{'white--text': item.white, 'blue1': !item.white}" :to="{ name: 'frontend.works' }"><v-icon small class="mr-5">mdi-chevron-left</v-icon>Back to all projects</router-link>
                            <div class="h2 fontw-700 mt-4 mt-sm-0 mb-1 montserrat text-center text-sm-left" :class="{'white--text': item.white, 'dark1': !item.white}" v-html="item.title"></div>
                            <p class="h5 pb-10 opensans text-center text-sm-left" :class="{'white--text-06': item.white, 'dark2': !item.white}" v-html="item.short_desc"></p>
                            <p class="h5 mb-md-16 pb-10 opensans text-center text-sm-left" :class="{'white--text': item.white, 'dark1': !item.white}" v-html="item.desc"></p>
                            <div class="d-flex justify-center justify-sm-start">
								<div v-if="item.id_service != 2" class="">
									<a v-if="item.url" :href="item.url" target="_blank">
	                                    <custom-button
	                                        class="circle fullblue px-8 fontw-700 h6 bg-transparent"  :class="{'white--text': item.white, 'dark1': !item.white}"
	                                    >
	                                        Visit website <v-icon x-small class="ml-3">mdi-plus</v-icon>
	                                    </custom-button>
	                                </a>
								</div>
								<div v-if="item.id_service == 2" class="">
									<div v-if="item.android || item.ios">
										<a v-if="item.android" :href="item.android" class="mr-5">
											<img :src="require('@/assets/img/google-play-badge.png')" alt="" width="180px;">
										</a>
										<a v-if="item.ios" :href="item.ios" class="">
											<img :src="require('@/assets/img/app-store-badge.png')" alt="" width="180px;">
										</a>
									</div>
									<div v-else>
										<a v-if="item.url" :href="item.url" target="_blank">
		                                    <custom-button
		                                        class="circle fullblue px-8 fontw-700 h6 bg-transparent"  :class="{'white--text': item.white, 'dark1': !item.white}"
		                                    >
		                                        Visit website <v-icon x-small class="ml-3">mdi-plus</v-icon>
		                                    </custom-button>
		                                </a>
									</div>

								</div>
                            </div>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" class="d-none d-sm-block">
                            <v-img :src="getPoster()" class="h-100"/>
                            <img :src="getPoster()" class="w-100" alt="">
                        </v-col> -->
                    </v-row>
                </v-container>
            </div>
            <div class="white zi-1 p-relative">
                <v-container>
                    <p class="h2 montserrat bold mt-md-16 pt-8 text-center text-sm-left mb-16" data-aos="fade-right">Technology</p>
                    <!-- <p class="h4 montserrat fontw-md-600 text-center text-sm-left mb-16" data-aos="fade-right">We are using modern technologies that help us solve any issue you might have.</p> -->
                    <technology-block :items="technologies"/>
                </v-container>
            </div>
            <div class="mt-md-16">
                <v-container>
                    <v-row>
                        <v-col class="mt-10" cols="12" md="4">
                            <title-dotted title="Project <br/>Description"/>
                        </v-col>
                        <v-col class="mt-10" cols="12" md="6" offset-md="2">
                            <v-row>
                                <v-col cols="12" lg="6" v-for="(item, i) in item.features" :key="i">
                                    <card-circle-title :itemkey="i" :desc="item.description" class="mb-8"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div class="">
                <v-container>
                    <work-gallery :items="item.gallery"/>
                </v-container>
            </div>
            <v-container class="my-16">
                <p class="h2 montserrat bold py-16 text-center text-md-left">Our Work</p>
                <v-row>
                    <v-col v-for="(item,i) in others" :key="i" class="workblock" cols="12" sm="6" data-aos="fade-up">
                        <div class="" v-on:click="showWork(item)">
                            <work-block :item="item" />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <quest />
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/base/CustomButton.vue'
import TechnologyBlock from '@/components/base/TechnologyBlock.vue'
import TitleDotted from '@/components/base/TitleDotted.vue'
import CardCircleTitle from '@/components/base/CardCircleTitle.vue'
import WorkGallery from '@/components/base/WorkGallery.vue'
import WorkBlock from '@/components/base/WorkBlock.vue'
import Quest from '@/components/page/quest.vue'
import WaitLoading from '@/components/base/WaitLoading.vue'

export default {
    components: {
        CustomButton,
        TechnologyBlock,
        TitleDotted,
        CardCircleTitle,
        WorkGallery,
        WorkBlock,
        Quest,
        WaitLoading
    },
    metaInfo() {
        return {
            title: this.item ? this.item.title + ' | GBMF' : 'Project Page | GBMF',
        };
    },
    data: () => ({
        item: null,
        others: [],
        loading: true,
        technologies: {},
        bgheight: 0
    }),
    created(){
        this.$emit('headerClass', '');

    },
    mounted(){
        if(!this.$route.params.slug){
            this.$router.push({name: 'frontend.works'});
        }
        this.getWork();
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.getWork();
    },
    methods: {
        async getWork(){
            await this.axios.get(process.env.VUE_APP_API + 'work/' + this.$route.params.slug).then(response => {
                this.item = response.data.main;
                this.loading = false;
                this.others = response.data.other;
                if(this.item.technologies){
                    this.item.technologies.map((item) => {

                        if(!this.technologies[item.parent.slug])
                            this.$set(this.technologies, item.parent.slug, {})

                        this.$set(
                            this.technologies[item.parent.slug],
                            item.slug,
                            {
                                name: item.title,
                                slug: item.slug
                            }
                        )
                        // this.technologies[item.parent.slug].push({
                        //     name: item.title,
                        //     slug: item.slug
                        // });
                    })
                }
                window.scrollTo(0,0);
                if(this.item.white){
                    this.$emit('headerClass', 'headertransparentblue');
                }
            })
        },
        getPoster(){
            if(!this.item)
                return null;

            var result =  this.item.gallery.filter((itm) => {
                if(itm.main == 1)
                    return itm;
            });
            return process.env.VUE_APP_BASE + '/storage/' + result[0].image;
        },
        getBackground(){
            // this.calculateHeight();
            setTimeout(() => {
                this.calculateHeight()
            }, 1500);
            if(this.$vuetify.breakpoint.mdAndUp){
                if(this.item.background)
                    return process.env.VUE_APP_BASE + '/storage/' + this.item.background.image;
            }else{
                if(this.item.backgroundsm){
                    return process.env.VUE_APP_BASE + '/storage/' + this.item.backgroundsm.image;
                }
                else if(this.item.background){
                        return process.env.VUE_APP_BASE + '/storage/' + this.item.background.image;
                }
            }

        },
        showWork(item){
            this.loading = true;
            this.item = null;
            window.scrollTo(0, 0);
            this.$router.push({name: 'frontend.work', params: {slug: item.slug}});
        },
        calculateHeight(){
            this.bgheight = this.$refs.welcomebox.clientHeight + this.$refs.welcomebox.offsetTop;
            if(this.bgheight == 0){
                setTimeout(() => {
                    this.calculateHeight()
                }, 1500);
            }
        }
    }
}
</script>

<style lang="scss">
.work{
    .bgimage{
        top: 0;
        position: absolute;
        width: 100%;
        height: 850px;
        background-repeat: no-repeat;
        background-size: cover!important;
        background-position: center !important;
    }
    .bg-image{
        top: 0;
    }
    .v-icon{
        color: inherit;
    }
    .v-slide-group__prev, .v-slide-group__next{
        display: none;
    }
    @media all and (max-width:660px){
        .bgimage{
            height: 580px;
        }
    }
    @media all and (min-width: 660px){
        .description{
            width: 60%;
        }
        .bgimage{
            height: 1024px;
        }
    }
    @media all and (min-width: 1240px){
        .welcomeblock{
            height: 742px;
        }
    }
}
</style>
