<template>
    <div class="bg-blue">
        <v-container class="py-7 relative">
            <div class="rectangle-bgmainblue d-none d-md-block"></div>
            <div class="py-md-16 my-md-16 pt-16 montserrat">
<!--                <p class="h6 white&#45;&#45;text text-center text-sm-left" data-aos="fade-left">Answer 5 questions and get $500!</p>-->
                <p class="h2-3 white--text bold text-center text-sm-left" data-aos="fade-left">Answer just 4 questions and we’ll see if we match each other!</p>
<!--                <p class="h4-5 white&#45;&#45;text text-center text-sm-left" data-aos="fade-left">For each question you are going <br v-if="$vuetify.breakpoint.smAndDown"/>to get a $100 discount.</p>-->
            </div>
            <div class="my-md-10 py-10 text-center text-sm-left" data-aos="fade-left">
                <span @click.stop="$refs.getstarted.changeDialogget()">
                    <button-standard-large large="true" btnclass="btn-border-radius py-7 px-16 blue1 removebefore montserrat" hoverable="true">Get Started</button-standard-large>
                </span>
                <div class="mt-16 pt-7 ml-16 pl-8 d-none d-lg-block " data-aos="fade-left">
                    <img class="" :src="require('@/assets/icon/dotwhite.svg')">
                </div>
            </div>
        </v-container>
        <get-started-block ref="getstarted"/>
    </div>
</template>

<script>
import GetStartedBlock from '@/components/base/GetStartedBlock.vue'

export default {
    components: {
        GetStartedBlock
    }
}
</script>

<style lang="scss">
</style>
