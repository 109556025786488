<template>
    <router-link :to="'work/' + item.slug">
        <div class="workblockitem">
            <img :src="getImage()" class="rounded-20" style="width:100%;">
            <div class="mt--2">
                <p class="h3 montserrat bold ml-6 dark1">{{item.title}}</p>
                <p class="h5 opensans ml-6 dark1">{{item.short_desc}}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name:"WorkBlock",
    props:[
        'item'
    ],
    methods: {
        getImage(){
            if(this.item.poster)
            return process.env.VUE_APP_BASE + '/storage/' + this.item.poster.image;
        }
    }
}
</script>

<style lang="scss">
.workblockitem{
    transition: 0.3s;
}
@media all and (min-width: 930px){
    .workblockitem:hover{
        transform: scale(1.02);
    }
}
</style>
