<template>
    <div class="workgallery" >
        <div class="d-flex justify-space-between align-end mt-16 pt-8 mb-6">
            <p class="h2 montserrat bold mb-0 text-center text-md-left" data-aos="fade-right">Gallery</p>
            <div class="mb-3">
                <v-icon @click="prev()" class="changeicon pa-4 removeafter mr-5" small>mdi-chevron-left</v-icon>
                <v-icon @click="next()" class="changeicon pa-4 removeafter" small>mdi-chevron-right</v-icon>
            </div>
        </div>
        <v-slide-group
            ref="slidearrow"
            class="pa-4"
            show-arrows
        >
            <v-slide-item
                v-for="(item, i) in slides"
                :key="i"
            >
                <v-card
                    class="pa-4"
                    width="400"
                    flat
                >
                    <v-img :src="getImage(item)" position="top center" height="400" @click="modeldialog = i; dialog = true"/>
                </v-card>
            </v-slide-item>
            <template slot="next">
                <span ref="sidenextref" class="d-none"></span>
            </template>
            <template slot="prev">
                <span ref="sideprevref" class="d-none"></span>
            </template>
        </v-slide-group>
        <v-dialog
            v-model="dialog"
            fullscreen
            width="100%"
            height="100%"
            content-class="white"
        >
            <div class="h-100 d-flex justify-space-between align-center" v-touch="{
                                                                                      left: () => changeSlide(true),
                                                                                      right: () => changeSlide(false),
          }">
                <div class="text-right py-4 p-absolute zi-2" style="right:0; top: 0;">
                    <v-btn text retain-focus-on-click class="montserrat removebefore fontw-700" @click="dialog = false" :ripple="false">
                        Close <v-icon class="ml-2">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-icon large class="ma-3 d-none d-lg-block" @click="changeSlide(false)">mdi-chevron-left</v-icon>
                <img :src="getImageModal(modeldialog)" class="mx-auto" style="max-width:100%; max-height:100%;"/>
                <v-icon large class="ma-3 d-none d-lg-block" @click="changeSlide(true)">mdi-chevron-right</v-icon>
            </div>

        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'WorkGallery',
    props: ['items'],
    data: () => ({
        modeldialog: 0,
        dialog: false,
        slider: 0,
        slides: [],
        expand: true
    }),
    mounted(){
        this.getSlides();
        window.addEventListener('scrollLeft', this.handleScroll);
    },
    methods: {
        getImageModal(){
            return this.getImage(this.slides[this.modeldialog]);
        },
        getImage(image){
            if(image)
            return process.env.VUE_APP_BASE + '/storage/' + image.image;
        },
        changeSlide(next = true){
            if(next){
                this.modeldialog += 1;
                if(typeof(this.slides[this.modeldialog]) == 'undefined')
                    this.modeldialog = 0;
            }
            if(!next){
                this.modeldialog -= 1;
                if(this.modeldialog < 0)
                    this.modeldialog = this.slides.length - 1;
            }
            // return getIma
        },
        getSlides(){
            this.slides = this.items;
        },
        next(){
            this.$refs.sidenextref.click();
        },
        prev(){
            this.$refs.sideprevref.click();
        },
        handleScroll(){
            alert();
        }
    }
}
</script>

<style lang="scss">
.workgallery{
    .changeicon{
        transition: 0.3s;
        background-color: #e8eeff;
        border-radius: 100%;
    }
    @media all and (min-width: 930px){
        .changeicon:hover{
            background-color: #1e5cfb;
        }
    }
}
</style>
