<template>
    <div class="cardcircletitle pr-6">
        <div class="circle d-flex align-center mb-4">
            <p class="h2 montserrat bold relative mb-0" v-html="prepareNumber()"></p>
        </div>
        <p v-if="title" class="h4 fontw-700" v-html="title"></p>
        <p class="h5 text my-12" v-html="desc"></p>
    </div>
</template>

<script>
export default {
    name: 'CardCircleTitle',
    props: ['itemkey', 'title', 'desc', ''],
    methods: {
        prepareNumber(){
            const itm = this.itemkey + 1;
            if(itm < 10)
                return '0' + itm;

            return itm;
        }
    }
}
</script>

<style lang="scss">
.cardcircletitle{
    .circle::before{
        content: " ";
        margin-left: -38px;
        position: absolute;
        display: inline-block;
        width: 114px !important;
        height: 114px !important;
        border-radius: 114px !important;
        background: #1E5CFB;
        opacity: 0.1;
        transition: 0.3s;
    }
    .text{
        color: #323232;
        opacity: 0.6;
    }
}
.cardcircletitle.elipse{
    .circle::before{
        border-radius: 40% 17% 52% 48% / 35% 44% 54% 17%  !important;
        margin-top: -30px;
    }
}
@media all and (min-width: 930px){
    .cardcircletitle:hover{
        .circle::before{
            opacity: 1;
        }
        .circle{
            color: #ffffff;
        }
    }
}

</style>
