<template>
    <div class="">
        <div v-if="circle" class="wearcircle" data-aos="fade-right">
            <p class="h2 montserrat bold relative pt-13 pl-4 pl-md-0 pt-md-0 mb-lg-16 pb-lg-10" v-html="title"></p>
        </div>
        <p v-if="!circle" class="h2 montserrat bold relative pt-13 pl-4 pl-md-0 pt-md-0 mb-lg-16 pb-lg-10" v-html="title"></p>

        <div v-if="button" class="text-cnter my-16 pt-16 d-none d-md-block" data-aos="fade-right">
            <router-link :to="{ name: '', params: {} }">
                <button-standard-large large="true" outlined btnclass="btn-border-radius border-silver-01 removebefore py-7 px-16">Show more</button-standard-large>
            </router-link>
        </div>
        <div class="d-none d-md-block" data-aos="fade-right">
            <img class="" :src="require('@/assets/icon/dotblue.svg')">
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleDotted',
    props: ['title', 'circle', 'button']
}
</script>

<style lang="scss">
</style>
